import React, { useEffect, useState } from "react";
import styles from "./nominee-info.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import TGIcon from "../../../../../../shared/tg-icon";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { useTranslation } from "react-i18next";
import TGIconLabel from "../../../../../../shared/tg-icon-label";
import { fetchProfileRequest } from "../../../../slice/fetchProfileSlice";
import {
  fetchNomineeRequest,
  removeNomineeRequest,
  resetRemoveNomineeDetails,
  getNomineeTermsFailure,
  getNomineeTermsSuccess,
  setPaidInsertState,
  setMemberIconState,
} from "../../../../slice/fetchNomineeSlice";
import { useLocation, useNavigate } from "react-router-dom";
import TGModal from "../../../../../../shared/tg-modal";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import { config } from "../../../../../../config/global";
import moment from "moment";
import NomineeQuota from "../../../../../../shared/nominee-quota";

interface Props {
  profileName?: string;
}
export const NomineeInfo = (props: Props) => {
  const TERMS_URL = config.TEAMSITE_NOMINEE_TERMS;
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [existingNominees, setExistingNominees] = useState<any>([]);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [deleteNomineeName, setDeleteNomineeName] = useState("");
  const [deleteNomineeDetails, setDeleteNomineeDetails] = useState<any>([]);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showNomineeQuotaPopup, setShowNomineeQuotaPopup] = useState(false);
  const [insufficientMilesPopup, setInsufficientMilesPopup] = useState(false);
  // const [showMaxDeleteMsg,setshowMaxDeleteMsg] = useState(false);
  const [disableAddNomineeButon, setDisableAddNomineeButton] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const getCountryFromUrl = i18n.language?.toUpperCase();
  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );

  const nomineeDetails = useSelector((state) => state?.osciReducer?.nominee?.nomineeData);
  const RemoveNomineeResponse = useSelector(
    (state: RootState) => state?.osciReducer?.nominee
  );
  const { nomineeTermsData, memberIcon } = useSelector(
    (state: any) => state?.osciReducer?.nominee
  );
  const [show, setShow] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchProfileRequest());
  }, [dispatch]);
  useEffect(() => {
    if (profileDetails?.memberID) {
      dispatch(fetchNomineeRequest(profileDetails?.memberID));
    }
    switch (profileDetails.privilegeCard) {
      case "MEMBER":
        dispatch(setMemberIconState("nominee-member-icon"));
        break;
      case "SILVER":
        dispatch(setMemberIconState("nominee-silver-icon"));
        break;
      case "GOLD":
        dispatch(setMemberIconState("nominee-gold-icon"));
        break;
      case "PLATINUM":
        dispatch(setMemberIconState("nominee-platinum-icon"));
        break;
      default:
        break;
    }
  }, [profileDetails]);

  useEffect(() => {
    setExistingNominees(nomineeDetails.nomineeDetail);
    if (memberIcon !== "nominee-gold-icon") {
      if (
        Object.keys(nomineeDetails)?.length > 0 &&
        nomineeDetails?.existingNominee ===
          nomineeDetails?.maxNumberOfNominees &&
        nomineeDetails?.currentPaidInsertsForCurrentTier <
          nomineeDetails?.maxPaidInsertsForCurrentTier
      ) {
        setDisableAddNomineeButton(true);
      } else {
        setDisableAddNomineeButton(false);
      }
    } else {
      if (
        nomineeDetails?.currentInitialFreeInserts <
        nomineeDetails?.maxInitialFreeInserts
      ) {
        setDisableAddNomineeButton(false);
      } else {
        if (
          nomineeDetails?.existingNominee ===
          nomineeDetails?.maxNumberOfNominees
        ) {
          setDisableAddNomineeButton(true);
        } else {
          setDisableAddNomineeButton(false);
        }
      }
    }
  }, [nomineeDetails]);

  useEffect(() => {
    if (RemoveNomineeResponse.removeNomineeStatus.status == true) {
      setShowRemovePopup(false);
      window.scrollTo(0, 0);
      dispatch(fetchNomineeRequest(profileDetails?.memberID));
      dispatch(resetRemoveNomineeDetails());
    } else {
    }
  }, [dispatch, RemoveNomineeResponse]);

  const navigateToAddNominee = () => {
    if (disableAddNomineeButon) {
    } else {
      if (
        nomineeDetails?.freeAdd > 0 ||
        nomineeDetails?.currentInitialFreeInserts <
          nomineeDetails?.maxInitialFreeInserts
      ) {
        dispatch(setPaidInsertState(false));
        navigate(`/${i18n.language}/myprofile/add-nominee`);
      } else {
        const remainingMiles = nomineeDetails?.remainingMiles;
        const convertedRemainingMiles = Number(
          remainingMiles.replaceAll(",", "")
        );
        if (
          convertedRemainingMiles < nomineeDetails.additionalInsertCostInMiles
        ) {
          setInsufficientMilesPopup(true);
        } else {
          dispatch(setPaidInsertState(true));
          navigate(`/${i18n.language}/myprofile/add-nominee`);
        }
      }
    }
  };

  const handleRemoveNominee = (nominee: any) => {
    setShowRemovePopup(true);
    let deleteRequest = {
      formOfPayment: nomineeDetails?.formOfPayment,
      nomineeID: nominee.nomineeId,
      salutation: nominee.salutation,
      firstName: nominee.firstName,
      lastName: nominee.lastName,
      middleName: nominee.middleName,
      suffix: nominee.suffix,
      dateOfBirth: nominee.dateOfBirth,
    };
    let deleteNomineeName =
      deleteRequest.firstName + " " + deleteRequest.lastName;
    setDeleteNomineeName(deleteNomineeName);
    setDeleteNomineeDetails(deleteRequest);
  };

  const handleCloseModal = () => {
    setShowRemovePopup(false);
  };

  const removeNominee = () => {
    dispatch(removeNomineeRequest(deleteNomineeDetails));
  };

  const sendMail = () => {
    window.location.href = process.env.REACT_APP_ROP_MAIL || "";
  };

  const handleTerms = () => {
    setShowTerms(true);
  };

  const showNominee = () => {
    setShowNomineeQuotaPopup(true);
  };

  const handleNomineeQuotaClose = () => {
    setShowNomineeQuotaPopup(false);
  };

  const handleInsufficientMilesPopupClose = () => {
    setInsufficientMilesPopup(false);
  };

  const navigateToPurchaseMiles = () => {
    window.open("", "_blank");
  };
  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: TERMS_URL,
      isTeamsite: true,
      successAction: getNomineeTermsSuccess,
      errorAction: getNomineeTermsFailure,
    });
  }, [dispatch]);

  const handleCloseTermsModal = () => {
    setShowTerms(false);
  };

  useEffect(() => {
    if (showTerms) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showTerms]);

  useEffect(() => {
    if (showNomineeQuotaPopup) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showNomineeQuotaPopup]);

  useEffect(() => {
    if (showRemovePopup) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else if (!showRemovePopup) {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showRemovePopup]);

  useEffect(() => {
    if (insufficientMilesPopup) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [insufficientMilesPopup]);

  return (
    <>
      {isDesktopView && (
        <>
          <div className={styles.nomineeInfoContainer}>
            <div className={styles.headingContainer}>
              <div className={styles.heading}>
                <div className={styles.nomineeHeader}>
                  <span className={styles.nomineeContentheader}>
                    {t("label_nominee_header")}
                  </span>
                </div>
              </div>
              {(((nomineeDetails?.freeAdd > 0 ||
                nomineeDetails?.currentPaidInsertsForCurrentTier <
                  nomineeDetails?.maxPaidInsertsForCurrentTier) &&
                memberIcon !== "nominee-gold-icon") ||
                (memberIcon === "nominee-gold-icon" &&
                  (nomineeDetails?.currentInitialFreeInserts <
                    nomineeDetails?.maxInitialFreeInserts ||
                    nomineeDetails?.nextInsertType !==
                      "NOT-ALLOWED-IN-CURRENT-PERIOD"))) && (
                <div className={styles.addNomineeButtonContainer}>
                  <div
                    className={`${styles.addNomineeButton} ${
                      disableAddNomineeButon
                        ? styles.disableAddNominee
                        : styles.enableAddNominee
                    }`}
                    onClick={navigateToAddNominee}
                  >
                    <TGIcon icon={"add-icon"} fillColor={""} size={"20px"} />
                    <span className={styles.addNomineeText}>
                      {t("label_add_nominee")}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {existingNominees?.length > 0 && (
              <div className={styles.existingNomineesContent}>
                <div className={styles.nomineeDescription}>
                  <span>{t("label_nominee_description")}</span>&nbsp;
                  <span
                    className={styles.nomineeQuotaText}
                    onClick={showNominee}
                  >
                    {t("label_nominee_quota")}
                  </span>
                </div>
              </div>
            )}
            {existingNominees?.length > 0 && (
              <hr className={styles["nominee-seperation"]}></hr>
            )}
            {Object.keys(nomineeDetails)?.length > 0 &&
              ((nomineeDetails?.maxPaidInsertsForCurrentTier ===
                nomineeDetails?.currentPaidInsertsForCurrentTier &&
                memberIcon !== "nominee-gold-icon") ||
                (memberIcon === "nominee-gold-icon" &&
                  nomineeDetails?.nextInsertType ===
                    "NOT-ALLOWED-IN-CURRENT-PERIOD")) && (
                <div className={styles.maxmimumLimitError}>
                  <TGIcon icon="error-icon" fillColor="" size="30" />
                  <div className={styles.maxmimumLimitErrorText}>
                    {t("label_max_err")}
                  </div>
                </div>
              )}
            {Object.keys(nomineeDetails)?.length > 0 &&
              disableAddNomineeButon && (
                <div className={styles.maxmimumLimitError}>
                  <TGIcon icon="error-icon" fillColor="" size="30" />
                  <div className={styles.maxmimumLimitErrorText}>
                    {t("error_nominee_free_limit")}
                  </div>
                </div>
              )}
            {existingNominees?.length == 0 && (
              <div className={styles.nomineeContentContainer}>
                <div className={styles.emptyNomineeIcon}>
                  <TGIcon icon="empty-nominee-icon" fillColor="" size="" />
                </div>
                <span className={styles.emptyNomineeHeader}>
                  {t("label_no_nominees_header")}
                </span>
                <div className={styles.emptyNomineeContent}>
                  <span>{t("label_nominee_description")}</span>&nbsp;
                  <span
                    className={styles.nomineeQuotaText}
                    onClick={showNominee}
                  >
                    {t("label_nominee_quota")}
                  </span>
                </div>
              </div>
            )}
            {existingNominees?.length > 0 && (
              <h4 className={styles["nominee-count"]}>
                {existingNominees?.length}/5 {t("label_no_nominees")}
              </h4>
            )}
            {existingNominees?.length > 0 &&
              existingNominees?.map((nominee: any, xIndex: any) => (
                <div className={styles["nominee-section"]}>
                  <div className={styles["nominee-details"]}>
                    <h4>
                      #{xIndex + 1}&nbsp; {t("label_nominee_info_header")}
                    </h4>
                    <div className={styles["nominee-person-details"]}>
                      <div>
                        <label
                          htmlFor="title"
                          className={styles["nominee-details-label"]}
                        >
                          {t("enroll_title")}
                        </label>
                        <p className={styles["nominee-details-info"]}>
                          {nominee.salutation?.toUpperCase()}
                        </p>
                      </div>
                      <div>
                        <div>
                          <label
                            htmlFor="firstAndMiddleName"
                            className={styles["nominee-details-label"]}
                          >
                            {t("label_reset_first_name")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {nominee.firstName?.toUpperCase()} &nbsp;{" "}
                            {nominee.middleName?.toUpperCase()}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="lastName"
                            className={styles["nominee-details-label"]}
                          >
                            {t("label_manage_last_name")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {nominee.lastName?.toUpperCase()}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label
                            htmlFor="dateOfBirth"
                            className={styles["nominee-details-label"]}
                          >
                            {t("label_reset_dob")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {moment(nominee.dateOfBirth).format("DD MMMM YYYY")}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="royalOrchidPlusMemberId"
                            className={styles["nominee-details-label"]}
                          >
                            {t("profile_rop")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {nominee.ropNumber !== "" ? nominee.ropNumber : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles["nominee-remove"]}
                      onClick={() => handleRemoveNominee(nominee)}
                    >
                      <TGIconLabel
                        icon="bin-icon"
                        label={t("label_remove_nominee")}
                        position="left"
                      />
                    </div>
                  </div>
                  <hr className={styles["nominee-seperation"]}></hr>
                </div>
              ))}
            {existingNominees?.length == 0 && (
              <hr className={styles["nominee-seperation"]}></hr>
            )}
            <div className={styles["nominee-tnc-container"]}>
              <p className={styles.nomineeTncContent}>
                {t("label_nominee_view_terms").split("[contact]")[0]}
                <span
                  className={styles.nomineeTncNavigationContent}
                  onClick={sendMail}
                >
                  {t("label_nominee_contact_terms")}
                </span>
                {
                  t("label_nominee_view_terms")
                    .split("[contact]")[1]
                    .split("[terms]")[0]
                }
                <span
                  className={styles.nomineeTncNavigationContent}
                  onClick={handleTerms}
                >
                  {t("profile_terms")}.
                </span>
              </p>
            </div>
            {showTerms && (
              <TGModal
                show={showTerms}
                headingClass="headerText"
                handleClose={handleCloseTermsModal}
                buttonPresent={true}
                customOffCanvasClass={styles["notice-bar-offcanvas"]}
                centered={true}
                customModalClass={styles["notice-bar-model"]}
                buttonLabel={t("button_too_many_codes_ok")}
              >
                <h3 className={styles["notice-bar-header"]}>
                  {nomineeTermsData[0].mainHeader}
                </h3>
                <h4>{nomineeTermsData[0].subHeading}</h4>
                <ol className={styles["nominee-list"]}>
                  {nomineeTermsData[0]?.listContents?.map((x: any) => (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: x?.label,
                        }}
                      ></div>
                    </div>
                  ))}
                </ol>
                <div className={styles["add-nominee-terms-table"]}>
                  <div>
                    {nomineeTermsData[0]?.tableContents.tableData.map(
                      (x: any) => (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.label,
                            }}
                          ></div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </TGModal>
            )}
          </div>
        </>
      )}
      {!isDesktopView && (
        <>
          <div className={styles.mobileNomineeContainer}>
            <div className={styles.mobileNomineeHeaderContent}>
              <div className={styles.mbNomineeHeader}>
                <span className={styles.mbHeaderText}>
                  {" "}
                  {t("label_nominee_header")}
                </span>
              </div>
              {existingNominees?.length > 0 && (
                <div className={styles.existingNomineesContent}>
                  <div className={styles.nomineeDescription}>
                    <span>
                      {t("label_nominee_description").split(".")[0] + "."}
                    </span>
                    &nbsp;
                    <br />
                    <span>
                      {t("label_nominee_description").split(".")[1] + "."}
                    </span>
                    &nbsp;
                    <br />
                    <span>{t("label_nominee_description").split(".")[2]}</span>
                    &nbsp;{" "}
                    <span
                      className={styles.nomineeQuotaText}
                      onClick={showNominee}
                    >
                      {t("label_nominee_quota")}
                    </span>
                  </div>
                </div>
              )}

              {Object.keys(nomineeDetails)?.length > 0 &&
                ((nomineeDetails?.maxPaidInsertsForCurrentTier ===
                  nomineeDetails?.currentPaidInsertsForCurrentTier &&
                  memberIcon !== "nominee-gold-icon") ||
                  (memberIcon === "nominee-gold-icon" &&
                    nomineeDetails?.nextInsertType ===
                      "NOT-ALLOWED-IN-CURRENT-PERIOD")) && (
                  <div className={styles.maxmimumLimitError}>
                    <TGIcon icon="error-icon" fillColor="" size="75" />
                    <div className={styles.maxmimumLimitErrorText}>
                      {t("label_max_err")}
                    </div>
                  </div>
                )}
              {Object.keys(nomineeDetails)?.length > 0 &&
                disableAddNomineeButon && (
                  <div className={styles.maxmimumLimitError}>
                    <TGIcon icon="error-icon" fillColor="" size="75" />
                    <div className={styles.maxmimumLimitErrorText}>
                      {t("error_nominee_free_limit")}
                    </div>
                  </div>
                )}
            </div>
            {existingNominees?.length === 0 && (
              <div className={styles.nomineeContentContainer}>
                <div className={styles.emptyNomineeIcon}>
                  <TGIcon icon="empty-nominee-icon" fillColor="" size="" />
                </div>
                <span className={styles.emptyNomineeHeader}>
                  {t("label_no_nominees_header")}
                </span>
                <div className={styles.emptyNomineeContent}>
                  <span>{t("label_nominee_description")}</span>&nbsp;
                  <span
                    className={styles.nomineeQuotaText}
                    onClick={showNominee}
                  >
                    {t("label_nominee_quota")}
                  </span>
                </div>
              </div>
            )}
            {existingNominees?.length === 0 && (
              <hr className={styles["nominee-seperation"]}></hr>
            )}
            {existingNominees?.length > 0 && (
              <h4 className={`${styles["nominee-count"]}`}>
                {existingNominees?.length}/5 {t("label_no_nominees")}
              </h4>
            )}
            {existingNominees?.length > 0 &&
              existingNominees?.map((nominee: any, xIndex: any) => (
                <div className={styles["nominee-section"]}>
                  <div className={styles["nominee-details"]}>
                    <h4>
                      #{xIndex + 1}&nbsp; {t("label_nominee_info_header")}
                    </h4>
                    <div className={styles["nominee-person-details"]}>
                      <div>
                        <label
                          htmlFor="title"
                          className={styles["nominee-details-label"]}
                        >
                          {t("enroll_title")}
                        </label>
                        <p className={styles["nominee-details-info"]}>
                          {nominee.salutation}
                        </p>
                      </div>
                      <div className={styles.mobileNominee}>
                        <div>
                          <label
                            htmlFor="firstAndMiddleName"
                            className={styles["nominee-details-label"]}
                          >
                            {t("label_reset_first_name")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {nominee.firstName} &nbsp; {nominee.middleName}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="lastName"
                            className={styles["nominee-details-label"]}
                          >
                            {t("label_manage_last_name")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {nominee.lastName}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label
                            htmlFor="dateOfBirth"
                            className={styles["nominee-details-label"]}
                          >
                            {t("label_reset_dob")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {moment(nominee.dateOfBirth).format("DD MMM YYYY")}
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="royalOrchidPlusMemberId"
                            className={styles["nominee-details-label"]}
                          >
                            {t("profile_rop")}
                          </label>
                          <p className={styles["nominee-details-info"]}>
                            {nominee.ropNumber != "" ? nominee.ropNumber : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles["nominee-remove"]}
                      onClick={() => handleRemoveNominee(nominee)}
                    >
                      <TGIconLabel
                        icon="bin-icon"
                        label={t("label_remove_nominee")}
                        position="left"
                      />
                    </div>
                  </div>
                  <hr className={styles["nominee-seperation"]}></hr>
                </div>
              ))}
            <div className={styles["nominee-tnc-container"]}>
              <p className={styles.nomineeTncContent}>
                {t("label_nominee_view_terms").split("[contact]")[0]}
                <span
                  className={styles.nomineeTncNavigationContent}
                  onClick={sendMail}
                >
                  {t("label_nominee_contact_terms")}
                </span>
                {
                  t("label_nominee_view_terms")
                    .split("[contact]")[1]
                    .split("[terms]")[0]
                }
                <span
                  className={styles.nomineeTncNavigationContent}
                  onClick={handleTerms}
                >
                  {t("profile_terms")}.
                </span>
              </p>
            </div>
            {showTerms && (
              <TGModal
                show={showTerms}
                headingClass="headerText"
                handleClose={handleCloseTermsModal}
                buttonPresent={true}
                customOffCanvasClass={styles["notice-bar-offcanvas"]}
                centered={true}
                customModalClass={styles["notice-bar-model"]}
                buttonLabel={t("button_too_many_codes_ok")}
                backdrop="static"
              >
                <h3 className={styles["notice-bar-header"]}>
                  {nomineeTermsData[0].mainHeader}
                </h3>
                <h4>{nomineeTermsData[0].subHeading}</h4>
                <ol className={styles["nominee-list"]}>
                  {nomineeTermsData[0]?.listContents?.map((x: any) => (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: x?.label,
                        }}
                      ></div>
                    </div>
                  ))}
                </ol>
                <div className={styles["add-nominee-terms-table"]}>
                  <div>
                    {nomineeTermsData[0]?.tableContents.tableData.map(
                      (x: any) => (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.label,
                            }}
                          ></div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </TGModal>
            )}
            {(((nomineeDetails?.freeAdd > 0 ||
              nomineeDetails?.currentPaidInsertsForCurrentTier <
                nomineeDetails?.maxPaidInsertsForCurrentTier) &&
              memberIcon !== "nominee-gold-icon") ||
              (memberIcon === "nominee-gold-icon" &&
                (nomineeDetails?.currentInitialFreeInserts <
                  nomineeDetails?.maxInitialFreeInserts ||
                  nomineeDetails?.nextInsertType !==
                    "NOT-ALLOWED-IN-CURRENT-PERIOD"))) && (
              <div className={styles["mob-addnomineeButton-container"]}>
                <div
                  className={`${styles.mobaddNomineeButton} ${
                    disableAddNomineeButon
                      ? styles.disableAddNominee
                      : styles.enableAddNominee
                  }`}
                  onClick={navigateToAddNominee}
                >
                  <TGIcon icon={"add-icon"} fillColor={""} size={"20px"} />
                  <span className={styles.mobaddNomineeText}>
                    {t("label_add_nominee")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showRemovePopup && (
        <TGModal
          show={show}
          handleClose={handleCloseModal}
          buttonLabel="OK"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.tcModalFlex}>
            <div className={styles.tcModal}>
              <TGIcon fillColor={"none"} icon="remove-nominee-icon" />
              <div className={styles.modalContent}>
                <span className={styles.modalHeading}>
                  {t("label_remove_nominee") + "?"}
                </span>
                <span className={styles.modalDescription}>
                  {t("label_remove_nominee_msg").split("[name]")[0]}
                  <span className={styles.removeNomineeText}>
                    {deleteNomineeName}
                  </span>
                  {t("label_remove_nominee_msg").split("[name]")[1]}
                </span>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {isDesktopView && (
                <TGButtonVariants
                  label="Cancel"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="var(--primary-surface-weak, #F5EFFF)"
                  bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                  padding="12px 16px"
                  textColor="var(--primary-text, #684B9B)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="50px"
                  width={!isDesktopView ? "" : "128px"}
                  onClick={handleCloseModal}
                />
              )}
              <TGButtonVariants
                label="Remove"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#381B6B"
                bgColorHover="#3D2D5E"
                padding="12px 16px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                width={!isDesktopView ? "" : "23%"}
                onClick={removeNominee}
              />
              {!isDesktopView && (
                <TGButtonVariants
                  label="Cancel"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="var(--primary-surface-weak, #F5EFFF)"
                  bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                  padding="12px 16px"
                  textColor="var(--primary-text, #684B9B)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="50px"
                  width={!isDesktopView ? "" : "128px"}
                  onClick={handleCloseModal}
                />
              )}
            </div>
          </div>
        </TGModal>
      )}
      {showNomineeQuotaPopup && (
        <NomineeQuota
          showNomineeQuotaPopup={showNomineeQuotaPopup}
          memberIcon={memberIcon}
          nomineeDetails={nomineeDetails}
          handleClose={handleNomineeQuotaClose}
        />
      )}
      {insufficientMilesPopup && (
        <TGModal
          show={show}
          handleClose={handleInsufficientMilesPopupClose}
          buttonLabel="OK"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.tcModalFlex}>
            <div className={styles.tcModal}>
              <TGIcon fillColor={"none"} icon="insufficient-miles-icon" />
              <div className={styles.modalContent}>
                <span className={styles.modalHeading}>
                  {t("label_insufficient_miles")}
                </span>
                <span className={styles.modalDescription}>
                  {
                    t("label_insufficient_miles_description").split(
                      "[amount]"
                    )[0]
                  }
                  <span className={styles.modalDescription}>
                    {nomineeDetails?.additionalInsertCostInMiles.toLocaleString(
                      getCountryFromUrl
                    )}{" "}
                  </span>
                  {
                    t("label_insufficient_miles_description").split(
                      "[amount]"
                    )[1]
                  }{" "}
                </span>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {isDesktopView && (
                <TGButtonVariants
                  label={t("button_label_discard")}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="var(--primary-surface-weak, #F5EFFF)"
                  bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                  padding="12px 16px"
                  textColor="var(--primary-text, #684B9B)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="50px"
                  onClick={handleInsufficientMilesPopupClose}
                />
              )}
              <TGButtonVariants
                label={t("button_label_purchase_miles")}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#381B6B"
                bgColorHover="#3D2D5E"
                padding="12px 16px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                onClick={navigateToPurchaseMiles}
              />
              {!isDesktopView && (
                <TGButtonVariants
                  label={t("button_label_discard")}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="var(--primary-surface-weak, #F5EFFF)"
                  bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                  padding="12px 16px"
                  textColor="var(--primary-text, #684B9B)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="50px"
                  onClick={handleInsufficientMilesPopupClose}
                />
              )}
            </div>
          </div>
        </TGModal>
      )}
    </>
  );
};
