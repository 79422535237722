import React, { useEffect, useState, useRef } from "react";
import styles from "./life-style-component.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import TGIcon from "../../../../../../shared/tg-icon";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { Form, Dropdown, Spinner } from "react-bootstrap";
import { fetchLifeStyleRequest } from "../../../../slice/fetchLifeStyleSlice";
import { ProfileLifeStyleInfo } from "../../../../models/models";
import { useTranslation } from "react-i18next";
import countryData from "../../../../../../../public/json/country-nationality-code-flag.json";
import TGCheckBox from "../../../../../../shared/tg-checkbox";
import termConditionData from "../../../../../../../public/json/termConditionsProfileComponents.json";
import hobbiesData from "../../../../../../../public/json/hobbiesAndInterests.json";
import sportsData from "../../../../../../../public/json/sports.json";
import magazineDate from "../../../../../../../public/json/magazine.json";
import newspapersData from "../../../../../../../public/json/newspapers.json";
import foodData from "../../../../../../../public/json/food.json";
import travelData from "../../../../../../../public/json/travel.json";
import Multiselect from "../../../../../../shared/tg-multiSelectDropdown";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import {
  getProfileLifeStyleInfoPending,
  setIsLifeStyleEditable,
} from "../../../../slice/profileLifeStyleSlice";
import { json } from "stream/consumers";

export const LifeStyle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [lifeStyleInfoData, setProfileLifeStyleInfoData] =
    useState<ProfileLifeStyleInfo>({
      maritalStatus: "",
      children: "",
      education: "",
      occupation: "",
      income: "",
      lifestyleCodeList: [],
      termsConditions: "",
    });
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  type Country = {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    dial_code: string;
    image: string;
    nationality: string;
  };
  type apiObject = {
    code: string;
    action: string;
  };
  const [errors, setErrors] = useState<Partial<ProfileLifeStyleInfo>>({});
  const [hobbiesAndInterestsArr, setHobbiesAndInterestsArr] = useState<any>([]);
  const [hobbiesAndInterestsList, setHobbiesAndInterestsList] = useState("");
  const [hobbiesAndInterestsDropdownArr, setHobbiesAndInterestsDropdownArr] =
    useState<any>([]);
  const [sportsArr, setSportsArr] = useState<any>([]);
  const [sportsList, setSportsList] = useState("");
  const [sportsDropdownArr, setSportsDropdownArr] = useState<any>([]);
  const [readingMagazineArr, setReadingMagazineArr] = useState<any>([]);
  const [readingMagazineList, setReadingMagazineListList] = useState("");
  const [readingMagazineDropdownArr, setReadingMagazineDropdownArr] =
    useState<any>([]);
  const [readingNewspapersArr, setReadingNewspapersArr] = useState<any>([]);
  const [readingNewspapersList, setReadingNewspapersList] = useState("");
  const [readingNewspapersDropdownArr, setReadingNewspapersDropdownArr] =
    useState<any>([]);
  const [foodArr, setFoodArr] = useState<any>([]);
  const [foodList, setFoodList] = useState("");
  const [foodDropdownArr, setFoodDropdownArr] = useState<any>([]);
  const [travelInterestsArr, settravelInterestsArr] = useState<any>([]);
  const [travelInterestsList, settravelInterestsList] = useState("");
  const [travelInterestsDropdownArr, settravelInterestsDropdownArr] =
    useState<any>([]);
  const [selected, setSelected] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  // const [isEditable, setIsEditable] = useState(false);
  const isEditable = useSelector(
    (state: RootState) =>
      state?.osciReducer?.profileLifeStyleSubmitResponse.setIsLifeStyleEditable
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const termConditionRef = useRef<any>(null);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const profileResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );

  var obj: any;

  useEffect(() => {
    dispatch(setIsLifeStyleEditable(false));
  }, []);

  //   useEffect(() => {
  //     obj = {
  //         "memberId": "BB00205",
  //         "maritalStatus": "M",
  //         "children": "",
  //         "education": "",
  //         "occupation": "",
  //         "income": "",
  //         "lifestyleCategoryList": [
  //             {
  //                 "type": "Hobbies & Interests",
  //                 "codeDesList": [
  //                     {
  //                         "code": "LH01",
  //                         "description": "Reading"
  //                     },
  //                     {
  //                         "code": "LH02",
  //                         "description": "Spa & Wellness"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "type": "Sports",
  //                 "codeDesList": [
  //                     {
  //                         "code": "LS22",
  //                         "description": "Cycling"
  //                     },
  //                     {
  //                         "code": "LS21",
  //                         "description": "Table tennis"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "type": "Dinning Preferences",
  //                 "codeDesList": [
  //                     {
  //                         "code": "LD01",
  //                         "description": "Thai"
  //                     },
  //                     {
  //                         "code": "LD02",
  //                         "description": "Japanese"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "type": "Reading Magazine",
  //                 "codeDesList": [
  //                     {
  //                         "code": "LM01",
  //                         "description": "Politics"
  //                     },
  //                     {
  //                         "code": "LM02",
  //                         "description": "Business/Economics"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "type": "Reading Newspapers",
  //                 "codeDesList": [
  //                     {
  //                         "code": "LN02",
  //                         "description": "Daily news"
  //                     },
  //                     {
  //                         "code": "LN03",
  //                         "description": "Entertainment"
  //                     }
  //                 ]
  //             },
  //             {
  //                 "type": "Travel Interests",
  //                 "codeDesList": [
  //                     {
  //                         "code": "LT06",
  //                         "description": "Food/Wine"
  //                     },
  //                     {
  //                         "code": "LT07",
  //                         "description": "Photography"
  //                     }
  //                 ]
  //             }
  //         ]
  //     }

  //     {obj.lifestyleCategoryList.map((data: any, index: number) => (
  //         console.log("lifestyleCategoryList",data.codeDesList)
  //     ))}
  //     var hobbiesInterests: Array<any> = [];
  //     var sports: Array<any> = [];
  //     var readingMagazine: Array<any> = [];
  //     var readingNewspapers: Array<any> = [];
  //     var dinningPreferences: Array<any> = [];
  //     var travelInterests: Array<any> = [];
  //     for (var i = 0; i < obj?.lifestyleCategoryList.length; i++) {

  //         if(obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "hobbies & interests"){
  //             for (var j = 0; j < obj?.lifestyleCategoryList[i]?.codeDesList?.length; j++) {
  //                 hobbiesInterests.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
  //             }
  //         }
  //         else if(obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "sports"){
  //             for (var j = 0; j < obj?.lifestyleCategoryList[i]?.codeDesList?.length; j++) {
  //                 sports.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
  //             }
  //         }
  //         else if(obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "reading magazine"){
  //             for (var j = 0; j < obj?.lifestyleCategoryList[i]?.codeDesList?.length; j++) {
  //                 readingMagazine.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
  //             }
  //         }
  //         else if(obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "reading newspapers"){
  //             for (var j = 0; j < obj?.lifestyleCategoryList[i]?.codeDesList?.length; j++) {
  //                 readingNewspapers.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
  //             }
  //         }
  //         else if(obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "dinning preferences"){
  //             for (var j = 0; j < obj?.lifestyleCategoryList[i]?.codeDesList?.length; j++) {
  //                 dinningPreferences.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
  //             }
  //         }
  //         else if(obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "travel interests"){
  //             for (var j = 0; j < obj?.lifestyleCategoryList[i]?.codeDesList?.length; j++) {
  //                 travelInterests.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
  //             }
  //         }
  //     }
  //     var hobbiesInterestsListData = hobbiesInterests?.length > 0 ? hobbiesInterests?.map((item) =>  item.description ).join(', ') : "-";
  //     var sportsListData = sports?.length ? sports?.map((item) =>  item.description ).join(', ') : "-";
  //     var readingMagazineListData = readingMagazine?.length ? readingMagazine?.map((item) =>  item.description ).join(', ') : "-";
  //     var readingNewspapersData = readingNewspapers?.length ? readingNewspapers?.map((item) =>  item.description ).join(', ') : "-";
  //     var dinningPreferencesData = dinningPreferences?.length ? dinningPreferences?.map((item) =>  item.description ).join(', ') : "-";
  //     var travelInterestsData = travelInterests?.length ? travelInterests?.map((item) =>  item.description ).join(', ') : "-";
  //     setHobbiesAndInterestsArr(hobbiesInterests);
  //     setHobbiesAndInterestsList(hobbiesInterestsListData);
  //     setSportsArr(sports);
  //     setSportsList(sportsListData);
  //     setReadingMagazineArr(readingMagazine);
  //     setReadingMagazineListList(readingMagazineListData);
  //     setReadingNewspapersArr(readingNewspapers);
  //     setReadingNewspapersList(readingNewspapersData);
  //     setFoodArr(dinningPreferences);
  //     setFoodList(dinningPreferencesData);
  //     settravelInterestsArr(travelInterests);
  //     settravelInterestsList(travelInterestsData);

  //     console.log("hobbiesInterests",hobbiesInterests);
  //     console.log("hobbiesInterests_hobbiesInterestsListData", hobbiesAndInterestsList)
  //     // const memberId = sessionStorage.getItem("memberId");
  //     // setMemberId(memberID);
  //   }, []);
  useEffect(() => {}, [lifeStyleInfoData.termsConditions]);
  useEffect(() => {
    dispatch(fetchLifeStyleRequest());
  }, [dispatch]);

  const getFieldLabel = (fieldName: keyof ProfileLifeStyleInfo) => {
    const labels: { [key in keyof ProfileLifeStyleInfo]: string } = {
      termsConditions: "required",
    };
    return labels[fieldName];
  };

  const myLifeStyleResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myLifeStyleData
  );

  const myLifeStyleSubmitResponse = useSelector(
    (state: RootState) => state?.osciReducer?.profileLifeStyleSubmitResponse
  );

  // console.log("myLifeStyleResponse", JSON.stringify(myLifeStyleResponse));
  // console.log("myLifeStyleSubmitResponse", myLifeStyleSubmitResponse?.profileLifeStyleInfoStatus?.message);

  useEffect(() => {
    // console.log("myLifeStyleResponseUseEff",JSON.stringify(myLifeStyleResponse))
    setProfileLifeStyleInfoData({
      ...lifeStyleInfoData,
      maritalStatus: myLifeStyleResponse.myLifeStyleSubmitData.maritalStatus,
      children: myLifeStyleResponse.myLifeStyleSubmitData.children,
      education: myLifeStyleResponse.myLifeStyleSubmitData.education,
      occupation: myLifeStyleResponse.myLifeStyleSubmitData.occupation,
      income: myLifeStyleResponse.myLifeStyleSubmitData.income,
    });

    // {
    //   myLifeStyleResponse.myLifeStyleSubmitData.lifestyleCategoryList?.map(
    //     (data: any, index: number) =>
    //       console.log("lifestyleCategoryList1", data.codeDesList)
    //   );
    // }
    var hobbiesInterests: Array<any> = [];
    var sports: Array<any> = [];
    var readingMagazine: Array<any> = [];
    var readingNewspapers: Array<any> = [];
    var dinningPreferences: Array<any> = [];
    var travelInterests: Array<any> = [];
    var obj = myLifeStyleResponse.myLifeStyleSubmitData;
    for (var i = 0; i < obj?.lifestyleCategoryList?.length; i++) {
      if (
        obj?.lifestyleCategoryList[i]?.type.toLowerCase() ===
        "hobbies & interests"
      ) {
        for (
          j = 0;
          j < obj?.lifestyleCategoryList[i]?.codeDesList?.length;
          j++
        ) {
          hobbiesInterests.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
        }
      } else if (
        obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "sports"
      ) {
        for (
          var j = 0;
          j < obj?.lifestyleCategoryList[i]?.codeDesList?.length;
          j++
        ) {
          sports.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
        }
      } else if (
        obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "reading magazine"
      ) {
        for (
          var j = 0;
          j < obj?.lifestyleCategoryList[i]?.codeDesList?.length;
          j++
        ) {
          readingMagazine.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
        }
      } else if (
        obj?.lifestyleCategoryList[i]?.type.toLowerCase() ===
        "reading newspapers"
      ) {
        for (
          var j = 0;
          j < obj?.lifestyleCategoryList[i]?.codeDesList?.length;
          j++
        ) {
          readingNewspapers.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
        }
      } else if (
        obj?.lifestyleCategoryList[i]?.type.toLowerCase() ===
        "dinning preferences"
      ) {
        for (
          var j = 0;
          j < obj?.lifestyleCategoryList[i]?.codeDesList?.length;
          j++
        ) {
          dinningPreferences.push(
            obj?.lifestyleCategoryList[i]?.codeDesList[j]
          );
        }
      } else if (
        obj?.lifestyleCategoryList[i]?.type.toLowerCase() === "travel interests"
      ) {
        for (
          var j = 0;
          j < obj?.lifestyleCategoryList[i]?.codeDesList?.length;
          j++
        ) {
          travelInterests.push(obj?.lifestyleCategoryList[i]?.codeDesList[j]);
        }
      }
    }
    var hobbiesInterestsListData =
      hobbiesInterests?.length > 0
        ? hobbiesInterests?.map((item) => item.description).join(", ")
        : "-";
    var sportsListData =
      sports?.length > 0
        ? sports?.map((item) => item.description).join(", ")
        : "-";
    var readingMagazineListData =
      readingMagazine?.length > 0
        ? readingMagazine?.map((item) => item.description).join(", ")
        : "-";
    var readingNewspapersData =
      readingNewspapers?.length > 0
        ? readingNewspapers?.map((item) => item.description).join(", ")
        : "-";
    var dinningPreferencesData =
      dinningPreferences?.length > 0
        ? dinningPreferences?.map((item) => item.description).join(", ")
        : "-";
    var travelInterestsData =
      travelInterests?.length > 0
        ? travelInterests?.map((item) => item.description).join(", ")
        : "-";
    setHobbiesAndInterestsArr(hobbiesInterests);
    setHobbiesAndInterestsList(hobbiesInterestsListData);
    setHobbiesAndInterestsDropdownArr(hobbiesInterests);
    setSportsArr(sports);
    setSportsList(sportsListData);
    setSportsDropdownArr(sports);
    setReadingMagazineArr(readingMagazine);
    setReadingMagazineListList(readingMagazineListData);
    setReadingMagazineDropdownArr(readingMagazine);
    setReadingNewspapersArr(readingNewspapers);
    setReadingNewspapersList(readingNewspapersData);
    setFoodArr(dinningPreferences);
    setFoodList(dinningPreferencesData);
    settravelInterestsArr(travelInterests);
    settravelInterestsList(travelInterestsData);
    setReadingNewspapersDropdownArr(readingNewspapers);
    setFoodDropdownArr(dinningPreferences);
    settravelInterestsDropdownArr(travelInterests);
  }, [myLifeStyleResponse]);

  useEffect(() => {
    if (
      myLifeStyleSubmitResponse?.profileLifeStyleInfoStatus?.status === true
    ) {
      dispatch(fetchLifeStyleRequest());

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [myLifeStyleSubmitResponse?.profileLifeStyleInfoStatus?.status]);

  useEffect(() => {
    if (myLifeStyleResponse?.isLoading === false) {
      dispatch(setIsLifeStyleEditable(false));
    }
  }, [myLifeStyleResponse?.isLoading]);

  const handleEditClick = () => {
    dispatch(setIsLifeStyleEditable(true));
  };

  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      setProfileLifeStyleInfoData({
        ...lifeStyleInfoData,
        termsConditions: value.toString(),
      });
      if (value == true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };

  const handleHobbiesSelection = (selectedList: any, selectedItem: any) => {
    setHobbiesAndInterestsDropdownArr(selectedList);
  };

  const handleHobbiesDeleteSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    setHobbiesAndInterestsDropdownArr(selectedList);
  };

  const handleSportsSelection = (selectedList: any, selectedItem: any) => {
    setSportsDropdownArr(selectedList);
  };

  const handleSportsDeleteSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    setSportsDropdownArr(selectedList);
  };

  const handleReadingMagazineSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    setReadingMagazineDropdownArr(selectedList);
  };

  const handleReadingMagazineDeleteSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    setReadingMagazineDropdownArr(selectedList);
  };

  const handleReadingNewspapersSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    setReadingNewspapersDropdownArr(selectedList);
  };

  const handleReadingNewspapersDeleteSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    setReadingNewspapersDropdownArr(selectedList);
  };

  const handleFoodSelection = (selectedList: any, selectedItem: any) => {
    setFoodDropdownArr(selectedList);
  };

  const handleFoodDeleteSelection = (selectedList: any, selectedItem: any) => {
    setFoodDropdownArr(selectedList);
  };

  const handleTravelInterestsSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    settravelInterestsDropdownArr(selectedList);
  };

  const handleTravelInterestsDeleteSelection = (
    selectedList: any,
    selectedItem: any
  ) => {
    settravelInterestsDropdownArr(selectedList);
  };

  const findDeletedArr = (apiResponse: any, selectedResponse: any) => {
    return apiResponse.filter(function (obj: any) {
      return !selectedResponse.some(function (obj2: any) {
        return obj.code == obj2.code;
      });
    });
  };

  const findNewlyAddedArr = (apiResponse: any, selectedResponse: any) => {
    return selectedResponse.filter(function (obj: any) {
      return !apiResponse.some(function (obj2: any) {
        return obj.code == obj2.code;
      });
    });
  };

  const findCommonArr = (apiResponse: any, selectedResponse: any) => {
    return apiResponse.filter(function (obj: any) {
      return selectedResponse.some(function (obj2: any) {
        return obj.code == obj2.code;
      });
    });
  };

  const handleLifeStyleUpdate = (event: React.FormEvent<HTMLButtonElement>) => {
    const newErrors: Partial<ProfileLifeStyleInfo> = {};
    Object.entries(lifeStyleInfoData).forEach(([key, value]) => {
      // if (!value?.trim()) {
      //   newErrors[key as keyof ProfileLifeStyleInfo] = getFieldLabel(
      //     key as keyof ProfileLifeStyleInfo
      //   );
      // }
    });
    if (
      errors.termsConditions == undefined ||
      errors.termsConditions == "error"
    ) {
      setErrors({ ...newErrors, termsConditions: "error" });
      newErrors["termsConditions"] = "error";
    }

    var filterUndefinedError;
    const keys = Object.keys(newErrors) as (keyof ProfileLifeStyleInfo)[];
    filterUndefinedError = keys.filter((key) => newErrors[key] !== undefined);
    var lifestyleCodeList: Array<apiObject> = [];
    var lifestyleFinalCodeList: Array<apiObject> = [];
    var deleteItems: any;

    if (filterUndefinedError.length === 0) {
      for (var i = 0; i < hobbiesAndInterestsDropdownArr.length; i++) {
        lifestyleCodeList = [
          ...lifestyleCodeList,
          {
            code: hobbiesAndInterestsDropdownArr[i].code,
            action: "A",
          },
        ];
      }

      for (var i = 0; i < sportsDropdownArr.length; i++) {
        lifestyleCodeList = [
          ...lifestyleCodeList,
          {
            code: sportsDropdownArr[i].code,
            action: "A",
          },
        ];
      }

      for (var i = 0; i < readingMagazineDropdownArr.length; i++) {
        lifestyleCodeList = [
          ...lifestyleCodeList,
          {
            code: readingMagazineDropdownArr[i].code,
            action: "A",
          },
        ];
      }

      for (var i = 0; i < readingNewspapersDropdownArr.length; i++) {
        lifestyleCodeList = [
          ...lifestyleCodeList,
          {
            code: readingNewspapersDropdownArr[i].code,
            action: "A",
          },
        ];
      }

      for (var i = 0; i < foodDropdownArr.length; i++) {
        lifestyleCodeList = [
          ...lifestyleCodeList,
          {
            code: foodDropdownArr[i].code,
            action: "A",
          },
        ];
      }

      for (var i = 0; i < travelInterestsDropdownArr.length; i++) {
        lifestyleCodeList = [
          ...lifestyleCodeList,
          {
            code: travelInterestsDropdownArr[i].code,
            action: "A",
          },
        ];
      }

      console.log(
        "getProfileLifeStyleInfoPending",
        JSON.stringify(lifestyleCodeList)
      );
      // if(lifestyleCodeList.length !== undefined && lifestyleCodeList.length > 0){
      dispatch(
        getProfileLifeStyleInfoPending({
          maritalStatus:
            myLifeStyleResponse.myLifeStyleSubmitData.maritalStatus,
          children: myLifeStyleResponse.myLifeStyleSubmitData.children,
          education: myLifeStyleResponse.myLifeStyleSubmitData.education,
          occupation: myLifeStyleResponse.myLifeStyleSubmitData.occupation,
          income: myLifeStyleResponse.myLifeStyleSubmitData.income,
          termsConditions: lifeStyleInfoData.termsConditions,
          lifestyleCodeList: lifestyleCodeList,
        })
      );
      // }
      // else {
      //   dispatch(setIsLifeStyleEditable(false));
      // }
    }
  };

  return (
    <>
      {!isEditable && (
        <div className={styles.lifeStyleInfoContainer}>
          <div className={styles.lifeStyleInfo}>
            <div className={styles.lifeStyleInfoHeadingContainer}>
              <div className={styles.lifeStyleInfoHeadingText}>
                {t("profile_Lifestyle_Info")}
              </div>
              {isDesktopView && (
                <div
                  onClick={handleEditClick}
                  className={styles.lifeStyleInfoHeadingButtonContainer}
                >
                  <div className={styles.lifeStyleInfoHeadingButton}>
                    <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                    <span className={styles.editButtonText}>
                      {t("label_edit")}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.lifeStyleInfoBodyContainer}>
              <div className={styles.lifeStyleInfoBodyHeadingContainer}>
                {t("profile_Lifestyle_Info")}
              </div>
              <div className={styles.lifeStyleInfoAddressContainer}>
                <div
                  className={styles.lifeStyleInfoBodyMailingAddressContainer}
                >
                  <div className={styles.lifeStyleInfoBodyAddressContainer}>
                    <div className={styles.lifeStyleInfoLabelContainer}>
                      <div className={styles.lifeStyleInfoLabel}>
                        {t("label_lifestyle_hobbies")}
                      </div>
                      <div className={styles.lifeStyleInfoText}>
                        {hobbiesAndInterestsList}
                      </div>
                    </div>
                  </div>
                  <div className={styles.lifeStyleInfoBodyCountryContainer}>
                    <div className={styles.lifeStyleInfoLabelContainer}>
                      <div className={styles.lifeStyleInfoLabel}>
                        {t("label_lifestyle_sports")}
                      </div>
                      <div className={styles.lifeStyleInfoText}>
                        {sportsList}
                      </div>
                    </div>
                  </div>
                  <div className={styles.lifeStyleInfoBodyCountryContainer}>
                    <div className={styles.lifeStyleInfoLabelContainer}>
                      <div className={styles.lifeStyleInfoLabel}>
                        {t("label_lifestyle_magazine")}
                      </div>
                      <div className={styles.lifeStyleInfoText}>
                        {readingMagazineList}
                      </div>
                    </div>
                  </div>

                  <div className={styles.lifeStyleInfoBodyCountryContainer}>
                    <div className={styles.lifeStyleInfoLabelContainer}>
                      <div className={styles.lifeStyleInfoLabel}>
                        {t("label_lifestyle_newspaper")}
                      </div>
                      <div className={styles.lifeStyleInfoText}>
                        {/* Daily News, Business/Marketing */}
                        {readingNewspapersList}
                      </div>
                    </div>
                  </div>

                  <div className={styles.lifeStyleInfoBodyCountryContainer}>
                    <div className={styles.lifeStyleInfoLabelContainer}>
                      <div className={styles.lifeStyleInfoLabel}>
                        {t("label_lifestyle_food")}
                      </div>
                      <div className={styles.lifeStyleInfoText}>{foodList}</div>
                    </div>
                  </div>

                  <div className={styles.lifeStyleInfoBodyCountryContainer}>
                    <div className={styles.lifeStyleInfoLabelContainer}>
                      <div className={styles.lifeStyleInfoLabel}>
                        {t("label_lifestyle_travel")}
                      </div>
                      <div className={styles.lifeStyleInfoText}>
                        {travelInterestsList}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {!isDesktopView && (
              <div
                onClick={handleEditClick}
                className={styles.lifeStyleInfoHeadingButtonContainer}
              >
                <div className={styles.lifeStyleInfoHeadingButton}>
                  <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                  Edit
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
      {isEditable && (
        <>
          <div className={styles.lifeStyleInfoContainer}>
            <div className={styles.lifeStyleInfo}>
              <div className={styles.lifeStyleInfoHeadingContainer}>
                <div className={styles.lifeStyleInfoHeadingText}>
                  {t("profile_Lifestyle_Info")}
                </div>
                {/* {isDesktopView && (
                <div
                  onClick={handleEditClick}
                  className={styles.lifeStyleInfoHeadingButtonContainer}
                >
                  <div className={styles.lifeStyleInfoHeadingButton}>
                    <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                    Edit
                  </div>
                </div>
              )} */}
              </div>
              <div className={styles.lifeStyleInfoBodyContainer}>
                <div className={styles.lifeStyleInfoBodyHeadingContainer}>
                  {t("profile_Lifestyle_Info")}
                </div>
                <div className={styles.lifeStyleInfoAddressContainer}>
                  <div
                    className={styles.lifeStyleInfoBodyMailingAddressContainer}
                  >
                    <div className={styles.lifeStyleInfoBodyAddressContainer}>
                      <div className={styles.lifeStyleInfoLabelContainer}>
                        <div className={styles.lifeStyleInfoLabel}>
                          {t("label_lifestyle_hobbies")}
                        </div>
                        {/* <div className={styles.lifeStyleInfoText}>
                        {hobbiesAndInterestsList}
                      </div> */}
                        <div className={styles.dropDownContainer}>
                          <Multiselect
                            displayValue="description"
                            options={hobbiesData}
                            selectedValues={hobbiesAndInterestsArr}
                            placeholder=""
                            showArrow={true}
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleHobbiesDeleteSelection}
                            onSearch={function noRefCheck() {}}
                            onSelect={handleHobbiesSelection}
                            showCheckbox
                            isDesktopView={isDesktopView ? true : false}
                            title={t("label_lifestyle_hobbies")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.lifeStyleInfoBodyCountryContainer}>
                      <div className={styles.lifeStyleInfoLabelContainer}>
                        <div className={styles.lifeStyleInfoLabel}>
                          {t("label_lifestyle_sports")}
                        </div>
                        {/* <div className={styles.lifeStyleInfoText}>
                        {sportsList}
                      </div> */}
                        <div className={styles.dropDownContainer}>
                          <Multiselect
                            displayValue="description"
                            options={sportsData}
                            selectedValues={sportsArr}
                            placeholder=""
                            showArrow={true}
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleSportsDeleteSelection}
                            onSearch={function noRefCheck() {}}
                            onSelect={handleSportsSelection}
                            showCheckbox
                            isDesktopView={isDesktopView ? true : false}
                            title={t("label_lifestyle_sports")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.lifeStyleInfoBodyCountryContainer}>
                      <div className={styles.lifeStyleInfoLabelContainer}>
                        <div className={styles.lifeStyleInfoLabel}>
                          {t("label_lifestyle_magazine")}
                        </div>
                        {/* <div className={styles.lifeStyleInfoText}>
                        {readingMagazineList}
                      </div> */}
                        <div className={styles.dropDownContainer}>
                          <Multiselect
                            displayValue="description"
                            options={magazineDate}
                            selectedValues={readingMagazineArr}
                            placeholder=""
                            showArrow={true}
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleReadingMagazineDeleteSelection}
                            onSearch={function noRefCheck() {}}
                            onSelect={handleReadingMagazineSelection}
                            showCheckbox
                            isDesktopView={isDesktopView ? true : false}
                            title={t("label_lifestyle_magazine")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.lifeStyleInfoBodyCountryContainer}>
                      <div className={styles.lifeStyleInfoLabelContainer}>
                        <div className={styles.lifeStyleInfoLabel}>
                          {t("label_lifestyle_newspaper")}
                        </div>
                        {/* <div className={styles.lifeStyleInfoText}>
                        {readingNewspapersList}
                      </div> */}
                        <div className={styles.dropDownContainer}>
                          <Multiselect
                            displayValue="description"
                            options={newspapersData}
                            selectedValues={readingNewspapersArr}
                            placeholder=""
                            showArrow={true}
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleReadingNewspapersDeleteSelection}
                            onSearch={function noRefCheck() {}}
                            onSelect={handleReadingNewspapersSelection}
                            showCheckbox
                            isDesktopView={isDesktopView ? true : false}
                            title={t("label_lifestyle_newspaper")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.lifeStyleInfoBodyCountryContainer}>
                      <div className={styles.lifeStyleInfoLabelContainer}>
                        <div className={styles.lifeStyleInfoLabel}>
                          {t("label_lifestyle_food")}
                        </div>
                        {/* <div className={styles.lifeStyleInfoText}>
                        {foodList}
                      </div> */}
                        <div className={styles.dropDownContainer}>
                          <Multiselect
                            displayValue="description"
                            options={foodData}
                            selectedValues={foodArr}
                            placeholder=""
                            showArrow={true}
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleFoodDeleteSelection}
                            onSearch={function noRefCheck() {}}
                            onSelect={handleFoodSelection}
                            showCheckbox
                            isDesktopView={isDesktopView ? true : false}
                            title={t("label_lifestyle_food")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.lifeStyleInfoBodyCountryContainer}>
                      <div className={styles.lifeStyleInfoLabelContainer}>
                        <div className={styles.lifeStyleInfoLabel}>
                          {t("label_lifestyle_travel")}
                        </div>
                        {/* <div className={styles.lifeStyleInfoText}>
                        {travelInterestsList}
                      </div> */}
                        <div className={styles.dropDownContainer}>
                          <Multiselect
                            displayValue="description"
                            options={travelData}
                            selectedValues={travelInterestsArr}
                            placeholder=""
                            showArrow={true}
                            onKeyPressFn={function noRefCheck() {}}
                            onRemove={handleTravelInterestsDeleteSelection}
                            onSearch={function noRefCheck() {}}
                            onSelect={handleTravelInterestsSelection}
                            showCheckbox
                            isDesktopView={isDesktopView ? true : false}
                            title={t("label_lifestyle_travel")}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        isDesktopView
                          ? styles.lifeStyleInfoacknowledgementContainer
                          : styles.lifeStyleInfoacknowledgementMobileContainer
                      }
                    >
                      <div
                        className={
                          isDesktopView
                            ? styles.lifeStyleInfoacknowledgement
                            : styles.lifeStyleInfoacknowledgementMobile
                        }
                      >
                        <div className={styles.checkTermsConditions}>
                          <TGCheckBox
                            isHtmlContent={true}
                            htmlContent={
                              termConditionData.termConditionHeadingtext
                            }
                            onChange={checkedChange}
                            checkref={termConditionRef}
                            className="checkLabel"
                            customLabel="checkLabel"
                          />

                          {!isDesktopView && errors.termsConditions && (
                            <div
                              className={
                                styles.lifeStyleInfoTermsConditionsError
                              }
                            >
                              <TGIcon
                                icon="error-icon"
                                fillColor=""
                                size="16"
                              />
                              <div
                                className={
                                  styles.lifeStyleInfoTermConditionText
                                }
                              >
                                {t("enroll_terms_condition")}
                              </div>
                            </div>
                          )}

                          {!isDesktopView && (
                            <div
                              className={
                                isDesktopView
                                  ? styles.submitProfContactInfoButton
                                  : styles.submitProfContactInfoMobileButton
                              }
                            >
                              <TGButtonVariants
                                label={
                                  myLifeStyleSubmitResponse.isLoading
                                    ? ""
                                    : "Save"
                                }
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="30px"
                                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                                bgColor="#684B9B"
                                padding="16px"
                                textColor="#FFFFFF"
                                fontFamily="Inter"
                                fontSize="16px"
                                fontStyle="normal"
                                fontWeight="700"
                                lineHeight="160%"
                                borderDisabled="0.791px solid #E3D4FE"
                                bgColorDisabled="#E3D4FE"
                                boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                                bgColorHover="#684b9b"
                                borderHover="0.791px solid #E3D4FE"
                                boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                                height="50px"
                                width="100%"
                                onClick={handleLifeStyleUpdate}
                              >
                                {myLifeStyleSubmitResponse.isLoading ? (
                                  <>
                                    <Spinner
                                      animation="grow"
                                      variant="light"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        margin: "5px",
                                      }}
                                    />
                                    <Spinner
                                      animation="grow"
                                      variant="light"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        margin: "5px",
                                      }}
                                    />
                                    <Spinner
                                      animation="grow"
                                      variant="light"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                        margin: "5px",
                                      }}
                                    />
                                  </>
                                ) : null}
                              </TGButtonVariants>
                            </div>
                          )}

                          {isDesktopView && (
                            <div className={styles.PrivacyNoticeContainer}>
                              {
                                <span
                                  className={
                                    styles.lifeStyleInfoPrivacyNoticeText
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: termConditionData.privacyNotice,
                                  }}
                                ></span>
                              }
                            </div>
                          )}

                          {isDesktopView && errors.termsConditions && (
                            <div
                              className={
                                styles.lifeStyleInfoTermsConditionsError
                              }
                            >
                              <TGIcon
                                icon="error-icon"
                                fillColor=""
                                size="16"
                              />
                              <div
                                className={
                                  styles.lifeStyleInfoTermConditionText
                                }
                              >
                                {t("enroll_terms_condition")}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {!isDesktopView && (
                        <div className={styles.PrivacyNoticeContainer}>
                          {
                            <span
                              className={styles.lifeStyleInfoPrivacyNoticeText}
                              dangerouslySetInnerHTML={{
                                __html: termConditionData.privacyNotice,
                              }}
                            ></span>
                          }
                        </div>
                      )}

                      {isDesktopView && (
                        <div
                          className={
                            isDesktopView
                              ? styles.submitProfContactInfoButton
                              : styles.submitProfContactInfoMobileButton
                          }
                        >
                          <TGButtonVariants
                            label={
                              myLifeStyleSubmitResponse.isLoading ? "" : "Save"
                            }
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="30px"
                            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColor="#684B9B"
                            padding="16px"
                            textColor="#FFFFFF"
                            fontFamily="Inter"
                            fontSize="16px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="160%"
                            borderDisabled="0.791px solid #E3D4FE"
                            bgColorDisabled="#E3D4FE"
                            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            bgColorHover="#684b9b"
                            borderHover="0.791px solid #E3D4FE"
                            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                            height="50px"
                            width="100%"
                            onClick={handleLifeStyleUpdate}
                          >
                            {myLifeStyleSubmitResponse.isLoading ? (
                              <>
                                <Spinner
                                  animation="grow"
                                  variant="light"
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    margin: "5px",
                                  }}
                                />
                                <Spinner
                                  animation="grow"
                                  variant="light"
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    margin: "5px",
                                  }}
                                />
                                <Spinner
                                  animation="grow"
                                  variant="light"
                                  style={{
                                    width: "10px",
                                    height: "10px",
                                    margin: "5px",
                                  }}
                                />
                              </>
                            ) : null}
                          </TGButtonVariants>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* {!isDesktopView && (
                <div
                  onClick={handleEditClick}
                  className={styles.lifeStyleInfoHeadingButtonContainer}
                >
                  <div className={styles.lifeStyleInfoHeadingButton}>
                    <TGIcon icon={"edit-icon"} fillColor={""} size={"20px"} />
                    Edit
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
