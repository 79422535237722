import React, { useEffect, useState } from "react";
import styles from "./add-nominee.module.scss";
import TGInput from "../../../../../../../shared/tg-input";
import { useTranslation } from "react-i18next";
import TGSelection from "../../../../../../../shared/tg-selection";
import TGDateDropdown from "../../../../../../../shared/tg-date-dropdown";
import useScreenSize from "../../../../../../../utils/hook/useScreenSize";
import { Form, Modal, Spinner } from "react-bootstrap";
import TGButton from "../../../../../../../shared/tg-button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmptyChecker,
  isValidRopId,
} from "../../../../../../../utils/helper";
import {
  addNomineeRequest,
  getNomineeTermsFailure,
  getNomineeTermsSuccess,
  resetAddNomineeDetails,
  updateNomineeTabState,
} from "../../../../../slice/fetchNomineeSlice";
import moment from "moment";
import TGIcon from "../../../../../../../shared/tg-icon";
import TGModal from "../../../../../../../shared/tg-modal";
import { config } from "../../../../../../../config/global";
import TGButtonVariants from "../../../../../../../shared/tg-button-variants";
import NomineeQuota from "../../../../../../../shared/nominee-quota";
import LoginOTP from "../../../../../../../shared/login-widget/login-otp";
import {
  resetNomineeOTPData,
  sendNomineeOTPRequest,
} from "../../../../../slice/addNomineeSlice";
import { RootState } from "../../../../../slice/RootReducer";

const AddNominee: React.FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activedTab } = location.state || [];
  const [errors, setErrors] = useState<Partial<any>>({
    title: { value: false, errorLabel: "" },
    firstName: { value: false, errorLabel: "" },
    middleName: { value: false, errorLabel: "" },
    lastName: { value: false, errorLabel: "" },
    birthDate: { value: false, errorLabel: "" },
    royalOrchidPlusMemberId: { value: false, errorLabel: "" },
    terms: { value: false, errorLabel: "" },
  });
  const [dataExist, setDataExist] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showInvalidRopError, setInvalidRopError] = useState<boolean>(false);
  const [errorMsg, setErrMsg] = useState<string>("");
  const [showPaidMessage, setShowPaidMessage] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [resetFormData, setResetFormData] = useState<any>({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    royalOrchidPlusMemberId: "",
  });
  const { t, i18n } = useTranslation();
  const getCountryFromUrl = i18n.language?.toUpperCase();
  const [showNomineeQuotaPopup, setShowNomineeQuotaPopup] = useState(false);
  const [issSessionExpired, setIsSessionExpired] = useState<boolean>(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [fullscreen, setFullScreen] = useState<true | undefined>(undefined);
  const [loginShow, setloginShow] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState(false);
  const [resendLimitExceeded, setResendLimitExceeded] = useState(false);

  const nomineeDetails = useSelector(
    (state: any) => state?.osciReducer?.nominee?.nomineeData
  );

  const addNomineeDetails = useSelector(
    (state: any) => state?.osciReducer?.nominee?.addNominee
  );

  const TERMS_URL = config.TEAMSITE_NOMINEE_TERMS;

  const handleTerms = () => {
    setShowTerms(true);
  };

  const {
    addNomineeError,
    nomineeTermsData,
    nomineeTermsError,
    paidInsert,
    isLoading,
    memberIcon,
  } = useSelector((state: any) => state?.osciReducer?.nominee);

  const {
    statusMessage,
    codeExpired,
    error: limitExceededError,
  } = useSelector((state: any) => state?.osciReducer?.addNominee);
  const paidNomineOTPResponse: any = useSelector(
    (state: RootState) => state?.osciReducer?.addNominee
  );

  const onCheckBoxSelect = () => {
    setChecked((state: any) => !state);
    if (!checked) {
      setErrors({ ...errors, terms: { value: false, errorLabel: "" } });
    }
  };
  const CustomCheckBox = () => (
    <Form.Check>
      <Form.Check.Input
        type="checkbox"
        checked={checked}
        onChange={onCheckBoxSelect}
        className={"pointer"}
      />
      <Form.Check.Label className={styles["nominee-terms"]}>
        {t("profile_accept")}{" "}
        <span onClick={handleTerms}>{t("profile_terms")}</span>
      </Form.Check.Label>
    </Form.Check>
  );

  const isMobileView = useScreenSize()?.deviceSize?.width < 768;

  const handleAddNominee = () => {
    setShowError(false);
    const newErrors: Partial<any> = {};
    for (const key in resetFormData) {
      if (isEmptyChecker(resetFormData[key])) {
        if (key === "title") {
          newErrors[key] = {
            value: true,
            errorLabel: t("enroll_title_required"),
          };
        } else if (key === "firstName") {
          newErrors[key] = {
            value: true,
            errorLabel: t("error_parent_middle_name_required"),
          };
        } else if (key === "lastName") {
          newErrors[key] = {
            value: true,
            errorLabel: t("error_reset_last_name_required"),
          };
        } else if (key === "birthDate") {
          newErrors[key] = {
            value: true,
            errorLabel: t("error_reset_dob_required"),
          };
        } else if (key === "royalOrchidPlusMemberId") {
          newErrors[key] = { value: false, errorLabel: "" };
        } else {
          newErrors[key] = { value: false, errorLabel: "" };
        }
      }
    }

    if (
      Object.values(resetFormData["royalOrchidPlusMemberId"]).length > 0 &&
      !isValidRopId(resetFormData["royalOrchidPlusMemberId"])
    ) {
      newErrors["royalOrchidPlusMemberId"] = {
        value: true,
        errorLabel: t("error_reset_ropid_invalid"),
      };
    } else {
      newErrors["royalOrchidPlusMemberId"] = {
        value: false,
        errorLabel: "",
      };
    }

    if (
      Object.values(resetFormData["firstName"]).length > 0 &&
      Object.values(resetFormData["firstName"]).length < 2
    ) {
      newErrors["firstName"] = {
        value: true,
        errorLabel: t("error_min_length_first_name"),
      };
    }

    if (
      Object.values(resetFormData["lastName"]).length > 0 &&
      Object.values(resetFormData["lastName"]).length < 2
    ) {
      newErrors["lastName"] = {
        value: true,
        errorLabel: t("error_min_length_last_name"),
      };
    }

    if (Object.values(resetFormData["birthDate"]).length > 0) {
      let year = resetFormData["birthDate"].split("-")[0];
      let month = resetFormData["birthDate"].split("-")[1];
      let day = resetFormData["birthDate"].split("-")[2];
      if (day === "") {
        newErrors["birthDate"] = {
          value: true,
          errorLabel: t("enroll_date_required"),
        };
      } else if (month === "00") {
        newErrors["birthDate"] = {
          value: true,
          errorLabel: t("enroll_month_required"),
        };
      } else if (day !== "" && month !== "" && year === "") {
        newErrors["birthDate"] = {
          value: true,
          errorLabel: t("enroll_year_required"),
        };
      } else {
        newErrors["birthDate"] = {
          value: false,
          errorLabel: "",
        };
      }

      if (day !== "" && month !== "" && year !== "") {
        var validDate = moment(resetFormData["birthDate"]);
        const dobDate = moment(resetFormData["birthDate"], "YYYY-MM-DD");
        const minAllowedDate = moment().subtract(2, "years");
        if (dobDate.isAfter(minAllowedDate)) {
          newErrors["birthDate"] = {
            value: true,
            errorLabel: t("enroll_age_validation"),
          };
        } else if (!validDate.isValid()) {
          newErrors["birthDate"] = {
            value: true,
            errorLabel: t("label_valid_dob_err"),
          };
        }
      }
    }

    const lessThan12 = moment().subtract(12, "years");
    const dobDate = moment(resetFormData["birthDate"], "YYYY-MM-DD");
    if (resetFormData["birthDate"] != "" && resetFormData["title"] != "") {
      if (
        dobDate?.isAfter(lessThan12) &&
        (resetFormData.title == t("enroll_mr") ||
          resetFormData.title == t("enroll_mrs") ||
          resetFormData.title == t("enroll_ms"))
      ) {
        newErrors["title"] = {
          value: true,
          errorLabel: t("enroll_title_error"),
        };
      }
      if (
        dobDate?.isBefore(lessThan12) &&
        (resetFormData.title == t("enroll_miss") ||
          resetFormData.title == t("enroll_mister"))
      ) {
        newErrors["title"] = {
          value: true,
          errorLabel: t("enroll_adult_greater_12"),
        };
      }
    }

    if (!checked) {
      newErrors["terms"] = {
        value: true,
        errorLabel: t("label_nominee_terms"),
      };
    } else {
      newErrors["terms"] = {
        value: false,
        errorLabel: "",
      };
    }
    setErrors(newErrors);
    let nomineeName;
    if (isEmptyChecker(resetFormData["middleName"])) {
      nomineeName =
        resetFormData["firstName"] +
        " " +
        resetFormData["middleName"] +
        " " +
        resetFormData["lastName"];
    } else {
      nomineeName =
        resetFormData["firstName"] + " " + resetFormData["lastName"];
    }
    setFullName(nomineeName);

    const NomineeError = Object.values(newErrors).some(
      (data) => data.value === true
    );

    if (!NomineeError) {
      if (paidInsert) {
        setShowConfirmPopup(true);
      } else {
        dispatch(addNomineeRequest(resetFormData));
      }
    }
  };

  const handleTitleSelection = (e: any, obj: any) => {
    setResetFormData({ ...resetFormData, title: obj.listName });
    setErrors({ ...errors, title: "" });
    errors["title"] = {
      value: false,
      errorLabel: "",
    };
    setErrors(errors);
    if (
      resetFormData["birthDate"] !== undefined &&
      resetFormData["birthDate"] !== "" &&
      resetFormData["birthDate"].split("-")[0] !== ""
    ) {
      setErrors({ ...errors, birthDate: { value: false, errorLabel: "" } });
    }
  };

  const handleInputFieldChange = (e: any) => {
    const { name, value } = e.target;
    setInvalidRopError(false);
    setResetFormData({ ...resetFormData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleDateChange = (date: string, month: string, year: string) => {
    const formattedDob = `${year}-${month}-${date}`;
    setResetFormData({ ...resetFormData, birthDate: formattedDob });
    if (resetFormData["title"] !== undefined && resetFormData["title"] !== "") {
      setErrors({ ...errors, title: { value: false, errorLabel: "" } });
    }
    errors["birthDate"] = {
      value: false,
      errorLabel: "",
    };
    setErrors(errors);
  };

  const handleCloseModal = () => {
    setShowOtpModal(false);
  };

  const handleCloseTermsModal = () => {
    setShowTerms(false);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmPopup(false);
  };

  const handleConfirmClick = () => {
    // dispatch(addNomineeRequest(resetFormData));
    setShowOtpModal(true);
    setShowConfirmPopup(false);
    dispatch(sendNomineeOTPRequest());
  };
  const handleClick = () => {
    // dispatch(addNomineeRequest(resetFormData));
    setShowPopup(true);
  };

  const showNominee = () => {
    setShowNomineeQuotaPopup(true);
  };

  const handleNomineeQuotaClose = () => {
    setShowNomineeQuotaPopup(false);
  };
  //OTP-part
  const onHidePanel = (value: boolean) => {
    setShowOtpModal(false);
  };
  const showOrHidePanel = (value: boolean) => {
    setShowOtpModal(false);
  };

  const showOrHideTooManyCodesPopUp = (value: boolean) => {
    // setEnabledTooManyCode(value);
    setloginShow(false);
    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("reset");
    sessionStorage.removeItem("newPin");
  };

  const showSessionExpirePopUp = () => {
    setIsSessionExpired(true);
    setloginShow(false);
    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("reset");
    sessionStorage.removeItem("newPin");
  };

  useEffect(() => {
    if (Object.values(nomineeDetails).length === 0) {
      navigate("/");
    }
    if (paidInsert) {
      setShowPaidMessage(true);
    } else if (!paidInsert) {
      setShowPaidMessage(false);
    }
  }, [nomineeDetails, navigate]);

  useEffect(() => {
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: TERMS_URL,
      isTeamsite: true,
      successAction: getNomineeTermsSuccess,
      errorAction: getNomineeTermsFailure,
    });
  }, [dispatch]);

  useEffect(() => {
    if (Object.values(addNomineeDetails).length > 0) {
      if (addNomineeDetails.statusMessage === "Success" || statusMessage) {
      }
    } else if (Object.values(addNomineeError).length > 0) {
      if (addNomineeError.response.data.code === "E9940") {
        setDataExist(true);
        setShowModal(true);
      } else if (addNomineeError.response.data.code === "-20105") {
        window.scroll(0, 0);
        setErrMsg("Something Went Wrong");
        setShowError(true);
      } else if (addNomineeError.response.data.code === "E5130") {
        setInvalidRopError(true);
        setErrMsg(addNomineeError.response.data.message);
      } else {
        window.scroll(0, 0);
        setErrMsg(addNomineeError.response.data.message);
        setShowError(true);
      }
    }
  }, [addNomineeDetails, addNomineeError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (addNomineeDetails.statusMessage === "Success") {
      setShowConfirmPopup(false);
      window.scrollTo(0, 0);
    } else {
      setShowConfirmPopup(false);
    }
  }, [addNomineeDetails, dispatch]);

  useEffect(() => {
    if (showModal) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("---html-position", "auto");
    }
  }, [showModal]);

  useEffect(() => {
    if (showConfirmPopup) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else if (!showConfirmPopup) {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showConfirmPopup]);

  useEffect(() => {
    if (showNomineeQuotaPopup) {
      document.documentElement.style.setProperty("--html-position", "fixed");
    } else {
      document.documentElement.style.setProperty("--html-position", "auto");
    }
  }, [showNomineeQuotaPopup]);
  useEffect(() => {
    if (isMobileView) {
      setFullScreen(true);
    } else {
      setFullScreen(undefined);
    }
  }, [isMobileView]);

  useEffect(() => {
    codeExpired && setShowPopup(codeExpired);
    if (limitExceededError?.response?.data?.code?.toString() === "013") {
      setResendLimitExceeded(true);
    }
  }, [codeExpired, limitExceededError]);

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowOtpModal(false);
    setResendLimitExceeded(false);
    dispatch(resetNomineeOTPData(false));
  };
  return (
    <div className={styles["add-nominee-container"]}>
      <div className={styles["add-nominee-background-section"]}>
        <div className={styles["add-nominee-background"]}>
          <div className={styles["add-nominee-heading"]}>
            <h4>{t("label_add_nominee")}</h4>
            <p>{t("enroll_rop_description")}</p>
          </div>
        </div>
        <div className={styles["add-nominee-form-container"]}>
          <div className={styles["add-nominee-wrapper"]}>
            {Object.values(addNomineeDetails).length === 0 && (
              <div className={styles["add-nominee-form"]}>
                <div className={styles["add-nominee-form-details"]}>
                  <h4>{t("label_nominee_info_header")}</h4>
                  {showError && (
                    <div className={styles["add-nominee-terms"]}>
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                      <div className={styles["add-nominee-terms-text"]}>
                        {errorMsg}
                      </div>
                    </div>
                  )}
                  {showPaidMessage && (
                    <div className={styles["add-nominee-paid-warning"]}>
                      <TGIcon icon="warning-icon" fillColor="" size="40" />
                      <div className={styles["add-nominee-paid-warning-text"]}>
                        {t("info_nominee_paid_msg").split("[miles]")[0]}
                        <span
                          className={styles["add-nominee-paid-warning-text"]}
                        >
                          {nomineeDetails?.additionalInsertCostInMiles}{" "}
                          {t("label_redemption_header_miles").toLowerCase()}
                        </span>
                        {t("info_nominee_paid_msg").split("[miles]")[1]}{" "}
                        <span
                          className={styles.nomineeQuotaText}
                          onClick={showNominee}
                        >
                          {t("label_nominee_quota")}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className={styles["nominee-person-details"]}>
                    <div>
                      <div className={styles["nominee-selection"]}>
                        <TGSelection
                          label={t("enroll_title")}
                          listData={[
                            { id: 1, listName: t("enroll_mr") },
                            { id: 2, listName: t("enroll_mrs") },
                            { id: 3, listName: t("enroll_ms") },
                            { id: 4, listName: t("enroll_mister") },
                            { id: 5, listName: t("enroll_miss") },
                            { id: 6, listName: t("enroll_title_prefer_not") },
                          ]}
                          onSelect={(e, ob) => {
                            handleTitleSelection(e, ob);
                          }}
                          isLoading={false}
                          disableSearch={true}
                          isError={errors.title?.value}
                          name="title"
                          errorLabel={errors.title?.errorLabel}
                          value={resetFormData.title}
                          isReadOnly={true}
                          isEnroll={true}
                        />
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <div>
                        <TGInput
                          label={t("enroll_firstname")}
                          onChange={handleInputFieldChange}
                          isError={errors.firstName?.value}
                          errorLabel={errors.firstName?.errorLabel}
                          name="firstName"
                          value={resetFormData.firstName}
                          placeholder={""}
                          customClassName={styles.fieldContainer}
                          maxLength={40}
                          validation="name"
                        />
                      </div>
                      <div>
                        <TGInput
                          label={t("enroll_lastName")}
                          onChange={handleInputFieldChange}
                          isError={errors.lastName?.value}
                          errorLabel={errors.lastName?.errorLabel}
                          name="lastName"
                          value={resetFormData.lastName}
                          placeholder={""}
                          customClassName={styles.fieldContainer}
                          maxLength={40}
                          validation="name"
                        />
                      </div>
                    </div>
                    {/* <div>
                      <div>
                        <TGInput
                          label={t("enroll_lastName")}
                          onChange={handleInputFieldChange}
                          isError={errors.lastName?.value}
                          errorLabel={errors.lastName?.errorLabel}
                          name="lastName"
                          value={resetFormData.lastName}
                          placeholder={""}
                          customClassName={styles.fieldContainer}
                          maxLength={40}
                          validation="name"
                        />
                      </div>
                      <div></div>
                    </div> */}
                    <div>
                      <div>
                        <TGDateDropdown
                          isEnroll={false}
                          isDesktop={isMobileView ? false : true}
                          label={t("label_reset_dob")}
                          isError={errors.birthDate?.value}
                          onDateChange={handleDateChange}
                          errorLabel={errors.birthDate?.errorLabel}
                        />
                      </div>
                      <div>
                        <TGInput
                          label={t("profile_rop")}
                          onChange={handleInputFieldChange}
                          isError={errors.royalOrchidPlusMemberId?.value}
                          errorLabel={
                            errors.royalOrchidPlusMemberId?.errorLabel
                          }
                          name="royalOrchidPlusMemberId"
                          value={resetFormData.royalOrchidPlusMemberId}
                          placeholder={t("label_reset_ropid_placeholder")}
                          customClassName={styles.fieldContainer}
                          validation="rop"
                        />
                        {showInvalidRopError && (
                          <div className={styles["tg-input-error-label"]}>
                            {errorMsg}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <>{CustomCheckBox()}</>
                  </div>
                  {errors.terms.value && (
                    <div className={styles["add-nominee-terms"]}>
                      <TGIcon icon="error-icon" fillColor="" size="16" />
                      <div className={styles["add-nominee-terms-text"]}>
                        {errors?.terms.errorLabel}
                      </div>
                    </div>
                  )}
                  <div className={styles["add-nominee-button-container"]}>
                    {!isMobileView && (
                      <div>
                        <TGButton
                          label={t("profile_cancel")}
                          variant="secondary"
                          buttonSize="sm"
                          customClass="bookingButtonCheckIn"
                          onClick={() => {
                            dispatch(updateNomineeTabState({ tabValue: 3 }));
                            navigate(`/${i18n.language}/myprofile`);
                            setShowError(false);
                          }}
                          disabled={false}
                        />
                      </div>
                    )}
                    <div>
                      <TGButton
                        label={t("label_add_nominee")}
                        variant="primary"
                        buttonSize="sm"
                        customClass="bookingButtonCheckIn"
                        onClick={handleAddNominee}
                        disabled={false}
                      />
                    </div>
                    {isMobileView && (
                      <div>
                        <TGButton
                          label={t("profile_cancel")}
                          variant="secondary"
                          buttonSize="sm"
                          customClass="bookingButtonCheckIn"
                          onClick={() => {
                            dispatch(updateNomineeTabState({ tabValue: 3 }));
                            navigate(`/${i18n.language}/myprofile`);
                            setShowError(false);
                          }}
                          disabled={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {addNomineeDetails.statusMessage === "Success" && (
              <div className={styles["add-nominee-success"]}>
                <div>
                  <h4>{t("label_add_nominee_success")}</h4>
                </div>
                <p>
                  {t("label_add_nominee_user_success").split("[name]")[0]}
                  <span>{fullName}</span>{" "}
                  {t("label_add_nominee_user_success").split("[name]")[1]}
                  {paidInsert && (
                    <div>
                      <span>
                        {nomineeDetails?.additionalInsertCostInMiles.toLocaleString(
                          getCountryFromUrl
                        )}{" "}
                      </span>
                      {t("label_nominee_deducted_msg").split("[miles]")[1]}
                    </div>
                  )}
                </p>
                <div>
                  <TGButton
                    label={t("button_back_to_nominees")}
                    variant="primary"
                    buttonSize="sm"
                    customClass="bookingButtonCheckIn"
                    onClick={() => {
                      dispatch(updateNomineeTabState({}));
                      dispatch(resetAddNomineeDetails());
                      navigate(`/${i18n.language}/myprofile`);
                      dispatch(updateNomineeTabState({ tabValue: 3 }));
                    }}
                    disabled={false}
                  />
                </div>
              </div>
            )}
            {dataExist && (
              <TGModal
                show={showModal}
                handleClose={handleCloseModal}
                buttonLabel="Ok"
                customModalClass={styles.customModalClass}
                customOffCanvasClass={styles.customOffCanvasClass}
                centered={true}
                backdrop="static"
              >
                <div className={styles["add-nominee-dialog-message-container"]}>
                  <div className={styles["add-nominee-dialog"]}>
                    <TGIcon fillColor={"none"} icon="duplicate-nominee-icon" />
                    <div className={styles["add-nominee-dialog-content"]}>
                      <span className={styles["add-nominee-dialog-heading"]}>
                        {t("label_nominee_already_exist")}
                      </span>
                      <span className={styles["add-nominee-dialog-message"]}>
                        {t("label_nominee_exist_message")}
                      </span>
                    </div>
                  </div>
                  <div className={styles["add-nominee-modal-container"]}>
                    <div>
                      <TGButton
                        label={t("button_too_many_codes_ok")}
                        variant="primary"
                        buttonSize="sm"
                        customClass="bookingButtonCheckIn"
                        onClick={() => {
                          setShowModal(false);
                          dispatch(resetAddNomineeDetails());
                        }}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </TGModal>
            )}
            {showTerms && (
              <TGModal
                show={showTerms}
                headingClass="headerText"
                handleClose={handleCloseTermsModal}
                buttonPresent={true}
                customOffCanvasClass={styles["notice-bar-offcanvas"]}
                centered={true}
                customModalClass={styles["notice-bar-model"]}
                buttonLabel={t("button_too_many_codes_ok")}
                backdrop={"static"}
              >
                <h3 className={styles["notice-bar-header"]}>
                  {nomineeTermsData[0].mainHeader}
                </h3>
                <h4 className={styles["notice-bar-subheading"]}>
                  {nomineeTermsData[0].subHeading}
                </h4>
                <ol className={styles["nominee-list"]}>
                  {nomineeTermsData[0]?.listContents?.map((x: any) => (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: x?.label,
                        }}
                      ></div>
                    </div>
                  ))}
                </ol>
                <div className={styles["add-nominee-terms-table"]}>
                  <div>
                    {nomineeTermsData[0]?.tableContents.tableData.map(
                      (x: any) => (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.label,
                            }}
                          ></div>
                        </>
                      )
                    )}
                  </div>
                </div>
              </TGModal>
            )}
          </div>
        </div>
      </div>
      {showConfirmPopup && (
        <TGModal
          show={showConfirmPopup}
          handleClose={handleCloseConfirmModal}
          buttonLabel="OK"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.tcModalFlex}>
            <div className={styles.tcModal}>
              <TGIcon fillColor={"none"} icon="nominee-paid-icon" />
              <div className={styles.modalContent}>
                <span className={styles.modalHeading}>
                  {t("info_nominee_confirm_msg_header")}
                </span>
                <span className={styles.modalDescription}>
                  {
                    t("info_nominee_confirm_msg_description").split(
                      "[miles]"
                    )[0]
                  }
                  <span>
                    {nomineeDetails?.additionalInsertCostInMiles.toLocaleString(
                      getCountryFromUrl
                    )}{" "}
                  </span>
                  {
                    t("info_nominee_confirm_msg_description").split(
                      "[miles]"
                    )[1]
                  }
                </span>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {!isMobileView && (
                <TGButtonVariants
                  label={t("profile_cancel")}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="var(--primary-surface-weak, #F5EFFF)"
                  bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                  padding="12px 16px"
                  textColor="var(--primary-text, #684B9B)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="50px"
                  width={isMobileView ? "" : "170px"}
                  onClick={handleCloseConfirmModal}
                />
              )}
              <TGButtonVariants
                label={isLoading ? "" : t("button_confirm")}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                bgColor="#381B6B"
                bgColorHover="#3D2D5E"
                padding="12px 16px"
                textColor="#FFFFFF"
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="160%"
                height="50px"
                width={isMobileView ? "" : "170px"}
                onClick={handleConfirmClick}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      animation="grow"
                      variant="light"
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "5px",
                      }}
                    />
                    <Spinner
                      animation="grow"
                      variant="light"
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "5px",
                      }}
                    />
                    <Spinner
                      animation="grow"
                      variant="light"
                      style={{
                        width: "10px",
                        height: "10px",
                        margin: "5px",
                      }}
                    />
                  </>
                ) : null}
              </TGButtonVariants>
              {isMobileView && (
                <TGButtonVariants
                  label={t("profile_cancel")}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="30px"
                  boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                  bgColor="var(--primary-surface-weak, #F5EFFF)"
                  bgColorHover="var(--primary-surface-weak, #F5EFFF)"
                  padding="12px 16px"
                  textColor="var(--primary-text, #684B9B)"
                  fontFamily="Inter"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="160%"
                  height="50px"
                  width={isMobileView ? "" : "170px"}
                  onClick={handleCloseConfirmModal}
                />
              )}
            </div>
          </div>
        </TGModal>
      )}
      {showNomineeQuotaPopup && (
        <NomineeQuota
          showNomineeQuotaPopup={showNomineeQuotaPopup}
          memberIcon={memberIcon}
          nomineeDetails={nomineeDetails}
          handleClose={handleNomineeQuotaClose}
        />
      )}
       {showOtpModal && !codeExpired && !showPopup &&(
         <Modal
           show={showOtpModal}
           onHide={() => {
             handleCloseModal("otpModel");
           }}
           centered
           className={`${styles["sign-in-rop-modal"]} ${
             fullscreen ? styles["parentContainer"] : ""
           } ${!loginShow && !fullscreen && styles["modal-container-rop-flow"]}`}
           fullscreen={fullscreen}
         >
           <Modal.Header closeButton className={styles.borderBottom}>
             <Modal.Title className={styles.titleWrapper}>
               {!showModal && (
                 <span
                   className="arrow"
                   style={{ cursor: "pointer" }}
                   onClick={() => {
                     setShowOtpModal(false);
                   }}
                 >
                   <TGIcon icon="arrow-left" fillColor="#684B9B" size="20" />
                 </span>
               )}
               <div className={styles.otpTitleSignIn}>
                 {t("label_otp_title")}
               </div>
             </Modal.Title>
           </Modal.Header>
           <Modal.Body className={styles.otpModalBody}>
             <LoginOTP
               onHideModal={() => onHidePanel}
               isHidden={showOrHidePanel}
               updateUserName={() => {}}
               showFrequentCodePopUp={showOrHideTooManyCodesPopUp}
               showSessionExpirePopUp={showSessionExpirePopUp}
               keyLoggedInProfile={"ROP"}
               page={"paid-nominee"}
               data={resetFormData}
             />
           </Modal.Body>
         </Modal>
       )}
    </div>
  );
};

export default AddNominee;
